

// Document is ready
$(document).ready(function(){
    
    $('.nicescroll').niceScroll();
    
    $('.news-slider .slick').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    });
    
    $('.single-slider').slick({ 
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true, 
        adaptiveHeight: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 2000
    });
    
    $('.scrolling-ad').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    });
    
    $('.photo-slider').each(function(){
        
        var slides = $(this).find('.slides'),
            thumbs = $(this).find('.thumbs');
        
        if( thumbs.length > 0 ){
            
            slides.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: thumbs
            });
            thumbs.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: slides,
                dots: false,
                centerMode: true,
                focusOnSelect: true
            });
            
        } else{
            
            slides.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: true,
            });
            
        }
        
    });

    $('.choices span').click(function(){
        $('.choices input').removeAttr('checked');
        $('.choices .fa').removeClass('fa-check-square').addClass('fa-square-o');
        
        $(this).find('input').attr('checked', true);
        $(this).find('.fa').removeClass('fa-square-o').addClass('fa-check-square');
    });
    
});


// Window has loaded its objects
$(window).load(function(){
   
   $('.gsc-input').attr('placeholder', 'Google custom search');
    
});